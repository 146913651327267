import { useNavigate } from 'react-router-dom'
import { ReactNode, useEffect, useLayoutEffect, useMemo } from 'react'
import { useAuthWrapperContext } from '@edx/web-auth'
import { NavBar } from './NavBar.tsx'
import { edxApi, setEDXApiAuthHeader } from '../services/edx-api.ts'
import { setEcommerceApiAuthHeader } from '../services/ecommerce-api.ts'
import { BackgroundBlur } from './BackgroundBlur.tsx'
import { useAppState } from '../state/app.ts'

interface AppWrapperProps {
  children: ReactNode
}

export function AppWrapper({ children }: AppWrapperProps) {
  const isDemo = useAppState((state) => state.isDemo)
  const { authState, logout, onAuthenticationError } = useAuthWrapperContext()
  const navigate = useNavigate()
  const links = useMemo(() => {
    const l: {
      title: string
      url: string
      onClick?: () => void
    }[] = [
      {
        title: 'Shop DNA Tests',
        url: 'https://www.alphadogdna.com/products/the-essential-dog-panel',
      },
      {
        title: 'Learning Center',
        url: 'https://www.alphadogdna.com/pages/learning-center',
      },
      {
        title: 'About Us',
        url: 'https://www.alphadogdna.com/pages/about-us',
      },
      {
        title: 'Contact Us',
        url: 'https://www.alphadogdna.com/pages/contact',
      },
    ]

    if (isDemo) {
      l.push({
        onClick: () => {},
        title: 'Build a Dog',
        url: '',
      })
    }

    return l
  }, [isDemo])

  useLayoutEffect(() => {
    edxApi.instance.interceptors.response.use(
      (res) => res,
      (error) => {
        if (error?.response?.status === 401) {
          onAuthenticationError()
        }

        return Promise.reject(error)
      },
    )
  }, [])

  setEDXApiAuthHeader({ token: authState?.authToken ?? '' })

  setEcommerceApiAuthHeader({
    token: authState?.authToken ?? '',
  })

  return (
    <div className="flex flex-col h-screen">
      <div className="relative z-[11]">
        <NavBar
          links={links}
          onClickActivate={() => {
            navigate('/activation')
          }}
          onClickLogo={() => {
            navigate('/')
          }}
          onClickProfile={() => {
            navigate('/profile')
          }}
          onClickSignOut={() => {
            logout()

            setTimeout(() => {
              // refresh the page to clear the cache/state singeltons
              window.location.reload()
            }, 1000)
          }}
        />
      </div>

      <main className="flex-1 bg-gradient-to-t from-doggradient-500 via-doggradient-300 via-81% to-white mt-[64px] overflow-x-scroll">
        <>
          <div className="relative -z-10">
            <BackgroundBlur />
          </div>
          <div className="p-6">{children}</div>
        </>
      </main>
    </div>
  )
}
