import { LoadingSpinner } from '@edx/react-common'
import { DogCardsHorizontalList } from './components/DogCardsHorizontalList.tsx'
import { DogFormDialog } from '../../components/DogFormDialog'
import { useDashboardScreenState } from './state.ts'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { DogButton } from '../../components/DogButton.tsx'
import ALPHADOG_SQUARE from '/alphadog-square.png'
import { ScreenTitle } from '../../components/ScreenTitle.tsx'
import { DogCard } from './components/DogCard.tsx'

export function DashboardScreen() {
  const state = useDashboardScreenState()
  const navigate = useNavigate()
  useEffect(() => {
    state.actions.loadDogs()
  }, [])

  useEffect(() => {
    if (state.selectedDog?.dog) {
      state.actions.selectDog(state.selectedDog?.dog.id)
    }
  }, [state.showingDogForm])

  return (
    <>
      <div className="flex justify-center items-center">
        <div className="lg:grid lg:grid-cols-2 w-full xl:justify-items-center">
          <section className="flex flex-col items-center justify-center space-y-12 relative max-w-[600px] z-10">
            {!state.dogs.length && state.dogsLoading ? (
              <LoadingSpinner overlay />
            ) : (
              <>
                {state.dogs.length > 0 ? (
                  <>
                    <ScreenTitle>Your Dog{state.dogs.length > 1 ? `s` : ''}</ScreenTitle>

                    <DogCardsHorizontalList />
                  </>
                ) : (
                  <>
                    <h2 className="text-4xl font-semibold mb-2">It's kinda empty here.</h2>
                    <p className="mb-8">Add a dog, or activate a test below!</p>
                  </>
                )}
              </>
            )}

            <div className="flex flex-col space-y-4 w-full max-w-[300px] font-montserrat text-edxred-500">
              <DogButton onClickFn={() => state.actions.showDogForm()}>Add a Dog</DogButton>

              <DogButton
                onClickFn={() => {
                  navigate('/activation')
                }}
              >
                Activate a Test
              </DogButton>

              <DogButton
                onClickFn={() => window.open('https://www.alphadogdna.com/products/the-essential-dog-panel', '_blank')}
              >
                Purchase Test
              </DogButton>
            </div>
          </section>

          <section className="flex items-center justify-self-stretch justify-center mt-[-476px] lg:mt-0">
            <img className="hidden lg:block fixed h-[70%] top-[158px]" src={ALPHADOG_SQUARE} alt="Etalon" />

            {state.selectedDog ? <DogCard /> : null}
          </section>
        </div>
      </div>

      {state.showingDogForm && (
        <DogFormDialog dogId={state.showingDogForm?.dogId} onClose={state.actions.closeDogForm} />
      )}
    </>
  )
}
